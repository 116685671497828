import React from 'react';
import styled from 'styled-components';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const ECHLMainContainer = styled.div`
  width: 100%;
  max-width: 980px;
  margin: auto;
  font-family: 'Open Sans', Arial, sans-serif;
`;

const TitleContainer = styled.div`
  margin: auto;
  text-align: center;
  background: #365888;
  color: #fff;
  padding: 30px 10px;

  span {
    text-decoration: underline;
  }
`;

const TitleImageContainer = styled.div`
  width: 90%;
  max-width: 450px;
  margin: 20px auto;
  background: #fff;

  img {
    margin-bottom: 0;
  }
`;

const BodyContainer = styled.div`
  text-align: center;
  padding: 30px;
  background: #eef5fb;

  h1 {
    font-size: calc(26px + 1.5vw);
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 20px;
  }
  h2 {
    text-align: center;
    font-style: italic;
    font-size: calc(22px + 1.5vw);
    font-weight: 600;
    padding: 0;
    margin: 20px 0;
    color: #365888;
  }
  h3 {
    font-size: calc(16px + 1vw);
    font-weight: 400;
    line-height: 1.3;
    margin: 0;
    font-style: italic;
  }

  p {
    font-size: calc(14px + 0.4vw);
    text-align: left;
    color: #365888;
  }
`;

const SubContainer = styled.div`
  background: linear-gradient(to bottom, #b7d543 0%, #80bc00 100%);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 28px 0;
  color: #fff;

  p {
    margin-bottom: 0;
    color: #fff;
    font-size: calc(14px + 0.4vw);
  }

  img {
    width: 60px;
    margin-bottom: 0;
  }

  img:nth-of-type(1) {
    width: 30px;
    margin-left: 10px;
    margin-right: auto;
  }
`;

const ListContainer = styled.section``;

const ItemNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  border-radius: 50%;
  background: #365888;
  color: #fff;
`;

const ListItem = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  p {
    margin-bottom: 0;
  }
`;

const caliberListItems = [
  'Can I expect a lower monthly payment?',
  'Will I close within 30 days?',
  'Can I manage my mortgage online?',
  'Should I switch to a 15-year fixed?',
  'Will I get updates that will keep me informed?',
];

const EchlThankYou = () => (
  <ECHLMainContainer>
    <div>
      <TitleContainer>
        <h1>YOU'VE BEEN MATCHED</h1>
        <h3>
          with our featured provider, <span>Caliber Home Loans</span>
        </h3>
      </TitleContainer>
      <TitleImageContainer>
        <img
          src="https://s3.amazonaws.com/sc-dba.com/echl-thankyou/images/caliber-home-loans-logo.png"
          alt="caliber home loans"
        />
      </TitleImageContainer>
      {/* img */}
      <BodyContainer>
        <h2>More About Caliber Home Loans</h2>
        <p>
          Caliber is a national lender and an awarded leader in the mortgage
          industry and offers a complete set of mortgage products including VA
          Loans and Streamlined Refinance for Military members, Fixed and
          Adjustable Rate conventional mortgages, along with other
          non-conventional products.
        </p>
        <p>
          We service 95% of loans we close. Unlike banks, mortgages are our only
          business allowing us to provide our customers fulfilling, affordable
          home financing.
        </p>
        <p>
          Whether you're interested in saving money each month, lowering you
          interest rate or even taking cash out with a refinance – Caliber can
          help you find the right mortgage that meets your financial needs.
        </p>
        <SubContainer>
          <p>Have questions? Caliber can help.</p>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/echl-thankyou/images/ckmark.png"
            alt="checkmark"
          />
          <img
            src="https://s3.amazonaws.com/sc-dba.com/echl-thankyou/images/refi.png"
            alt="refi house"
          />
        </SubContainer>
        <ListContainer>
          {caliberListItems.map((item, itemIndex) => (
            <ListItem key={itemIndex}>
              <ItemNumber>{itemIndex + 1}</ItemNumber>
              <p>{item}</p>
            </ListItem>
          ))}
        </ListContainer>
      </BodyContainer>
    </div>
  </ECHLMainContainer>
);

export default withThankyouWrapper(EchlThankYou);
